body{
  font-family: "Alata";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pointer{
  cursor: pointer;
}

.canvas-cont{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

.comment-input-wrapper{
  position: absolute;
  top: 2vh;
  left: 5vw;
  z-index: 10000;
  color: white;
}

input{
  color: black;
}

.footer-wrapper{
  position: absolute;
  z-index: 10000;
  bottom: 0;
  width: 100vw;
  height: auto;
  background: rgba(0, 0, 0, 0.322);
  display: flex;
  flex-direction: row;
}

.icon{
  position: relative;
  background: transparent;
  height: 50px;
  width: 50px;
  margin: 5px;
  cursor: pointer;
}

.rareaLogo{
  background-image: url("./assets/rareaLogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.listLogo{
  background-image: url("./assets/listIcon4.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  opacity: 0.8;
}

.banner{
  position: absolute;
  background: rgba(29, 29, 29, 0.5);
  color: rgba(255, 255, 255, 0.692);
  height: 30px;
  border-radius: 10px;
  width: 50vw;
  margin: 5px;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}


.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 50vw;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

.marquee2 span {
  animation-delay: 25s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper{
  position: absolute;
  z-index: 10000;
  width: 80vw;
  height: 80vh;
  border-radius: 5px;
  background: rgba(14, 14, 14, 0.9);
}

.about-wrapper{
  color: white;
}

.welcome-wrapper{
  height: 55vh;
  color: white;
}

.aboutText{
  color: white;
}

.text-wrapper{
  text-align: left;
  width: 80%;
  height: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.about-text-wrapper{
  text-align: left;
  width: 80%;
  height: 80%;
  overflow-y: scroll;
}

.text-wrapper::-webkit-scrollbar {
  display: none;
}

.about-text-wrapper::-webkit-scrollbar {
  display: none;
}

.lineCont{
  width: 100%;
  height: 100px;
}

.line{
  height: 1px;
  width: 600px;
  background: rgb(122, 122, 122);
  position: relative;
}

.disclaimer{
  font-size: 80%;
  color:rgb(122, 122, 122)
}

.rareaDescription{
  color:rgb(122, 122, 122)
}

.list-wrapper{
 
}

.input-wrapper{
  
}

button{
  background: rgba(255, 255, 255, 0.164);
  color: white;
  outline: none;
  border-radius: 10px;
  border-color: transparent;
  font-family: 'Alata';
  cursor: pointer;
  font-size: 15px;
  padding: 2px 15px;
}

button:focus {outline:0;}

button:hover{
  background: rgba(20, 20, 20, 0.164);
}

textarea{
  background: transparent;
  border-radius: 10px;
  border-color: rgba(255, 255, 255, 0.164);
  color: white;
  padding: 20px;
  width: 80%;
}

form{
  width: 80vw;
}

.close-wrapper{
  position: absolute;
  left: 15px;
  top: 10px;
  font-size: 20px;
  z-index: 500000;
  cursor: pointer;
  color: lightsalmon;
}

.listCont{
  overflow-y: scroll;
  height: 90%;
  width: 80%;
  color: white;
  text-align: left;
}

.listCont::-webkit-scrollbar {
  display: none;
}

.listItems{
  margin: 40px 0px;
}

.listItems::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  background: white;
  width: 40px;
  left: 100%;
  top: calc(50% - 2px);
}

.titles{
  color: lightsalmon;
}

.SSMMCont{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 35%;
  justify-content: space-around;
}

.logoSSMM{
  width: 40px;
  height: 40px;
  opacity: 0.5;
}

.logoSSMM:hover{
  opacity: 0.8;
}

.instagram{
  background-image: url('./assets/instaLogo.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.twitter{
  background-image: url('./assets/twitterLogo.png');
  background-repeat: no-repeat;
  background-size: cover;
}


/* Portrait */
@media screen and (orientation:portrait) and (max-width: 600px) {
  /* Portrait styles here */

  .welcome-wrapper{
    height: 85vh;
    color: white;
  }

}

@media screen and (orientation:portrait) and (max-width: 320px) {
  /* Portrait styles here */

  body{
    font-size: 13px;
  }

  .welcome-wrapper{
    height: 75vh;
    color: white;
  }

}

/* Landscape */
@media screen and (orientation:landscape) and (max-height: 700px) {
  /* Landscape styles here */

  body{
    font-size: 12px;
  }

  .welcome-wrapper{
    height: 70vh;
    color: white;
  }

}

@media screen and (orientation:landscape) and (max-height: 320px) {
  /* Landscape styles here */

  body{
    font-size: 12px;
  }

  .welcome-wrapper{
    height: 65vh;
    color: white;
  }

}